import { validationMixin } from 'vuelidate';

export default {
  mixins: [validationMixin],
  provide () {
    const validation = {};
    Object.defineProperty(validation, '$v', {
      enumerable: true,
      get: () => this.$v.form,
    });
    Object.defineProperty(validation, 'serverErrors', {
      enumerable: true,
      get: () => this.serverErrors,
      set (newValue) {
        this.serverErrors = newValue;
      },
    });
    Object.defineProperty(validation, 'validators', {
      enumerable: true,
      get: () => this.validators,
      set (newValue) {
        this.validators = newValue;
      },
    });
    Object.defineProperty(validation, 'form', {
      enumerable: true,
      get: () => this.form,
      set (newValue) {
        this.form = newValue;
      },
    });

    return { validation };
  },
  validations () {
    return this.validators;
  },
  data: () => ({
    serverErrors: {},
    form: {},
    isSubmitDisabled: true,
    validators: {
      form: {

      },
    },
  }),
  computed: {
    formInvalid () {
      return !Object.keys(this.form || {}).length || this.$v?.form?.$invalid;
    },
  },
  // FIXME: Тут в идеале нужно избавиться от watch. Но в других компонентах эта переменная тоже используется. Поэтому в будущем просто нужно переписать всю валидацию на новый vuelidate.
  // А пока вроде как immediate должен помочь от "too much recursion"
  watch: {
    formInvalid: {
      immediate: true,
      handler (val) {
        this.isSubmitDisabled = val;
      },
    },
  },
  methods: {
    setServerError (field, message) {
      this.$set(this.serverErrors, field, message);
    },
  },
};
