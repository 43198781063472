import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import { SInput } from '@strana-artw/ui-kit';
import { email, required } from 'vuelidate/lib/validators';
export default {
  name: 'FormFieldEmail',
  components: {
    SInput: SInput
  },
  inject: ['validation'],
  props: {
    field: {
      type: String,
      default: 'email'
    },
    autocomplete: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: String,
      default: ''
    },
    inlineConfirmation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errorMessage: function errorMessage() {
      var _this$validation$$v;
      var fieldValidation = (_this$validation$$v = this.validation.$v) === null || _this$validation$$v === void 0 ? void 0 : _this$validation$$v[this.field];
      if (!fieldValidation || !fieldValidation.$dirty) {
        return undefined;
      }
      var required = fieldValidation.required,
        email = fieldValidation.email,
        serverError = fieldValidation.serverError;
      if (!required && this.required) {
        return 'Введите email';
      }
      if (!email) {
        return 'Введите корректный email';
      }
      if (!serverError) {
        return this.validation.serverErrors[this.field] || 'Ошибка на сервере';
      }
      return undefined;
    },
    isEditing: function isEditing() {
      return !this.disabled && this.inlineConfirmation;
    }
  },
  created: function created() {
    var field = this.field,
      validation = this.validation;
    var validators = {
      email: email,
      serverError: function serverError() {
        return !this.serverErrors[field];
      }
    };
    if (this.required) {
      validators.required = required;
    }
    this.$set(this.validation.validators.form, field, validators);
    this.$set(validation.form, field, this.initialValue);
  },
  beforeDestroy: function beforeDestroy() {
    this.$delete(this.validation.form, this.field);
    this.$delete(this.validation.validators.form, this.field);
  },
  methods: {
    clearServerError: function clearServerError() {
      if (this.validation.serverErrors[this.field]) {
        this.$delete(this.validation.serverErrors, this.field);
      }
    }
  }
};